// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDkFNPg4HZVf5i9zEzc-2j7FDb_voWcqZQ",
  authDomain: "yvom2nd.firebaseapp.com",
  databaseURL: "https://yvom2nd-default-rtdb.firebaseio.com",
  projectId: "yvom2nd",
  storageBucket: "yvom2nd.appspot.com",
  messagingSenderId: "918046947529",
  appId: "1:918046947529:web:8db42a018f872d7531f518",
  measurementId: "G-HJWHVCDW4B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };